import './App.css';
import LandingPage from "./Pages/LandingPage";
import Main from "./Pages/Main"

function App() {
  return (
    <div className="App">
        <LandingPage />
    </div>
  );
}

export default App;
